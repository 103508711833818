*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main_container{
    width: 100%;
    position: fixed;
    left: 0px;
    right: 0px;
    top:0px;
    bottom: 0px;
    background: rgba(255,255,255, 0.8);
    overflow: auto;
    padding: 10px;
}

.login_container{
    max-width: 400px;
    margin: 50px auto;
    box-shadow: 0 0 10px gainsboro;
    padding: 20px;
}

.form-control{
    width: 100%;
    margin: 10px 0px;
    padding: 5px;
    border-radius: 5px;
   
}

.login_card{
    width: calc(50% - 20px);
    display: inline-block;
    text-align: center;
    padding: 30px;
    background-color: white;
    margin: 10px;
    text-decoration: none;
}   

.login_card img{
    width: 100%;
    height: 130px;
}

.login_card span{
    width: 100%;
}

.login_card:hover {
    text-decoration: none;
}

@media only screen and (max-width: 600px) {

    .login_card{
        width: calc(100% - 20px);
    }
}

@media only screen and (max-width: 400px) {

    .main_container{
        padding: 10px 0px;
    }
}
