*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background: url(./Images/background.jpg) no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
}

.header_strip{
    background: black;
}

.Logo_wrapper {
    background: url(./Images/ribbon.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
}

.Logo{
    text-align: center;
    font-size: 85px;
    font-style: italic;
    color: white;
}

.card_wrapper{
    padding: 5px 0px 10px;
    border-top: 5px solid #2e56b3;
    border-radius: 10px;
    box-shadow: 0 5px 10px #6ca8ab;
    text-align: center;
    background:white;
    cursor: pointer;
    transition: transform .5s;
}

.card_wrapper:hover{
    transform: scale(1.05, 1.05);
}

.card_wrapper h5{
    margin-bottom: 0px;
}

.card_icon{
    width: 70%;
    height: 100px;
    margin: 0px auto;
}

.side_menu_wrapper{
    position: fixed;
    top:0px;
    left:0px;
    z-index: 1;
    width: 220px;
    height: 100%;
    transition: left .3s;
}
.side_menu_wrapper_hide{
    left:-220px;
}

.side_menu{
    /* background-color: white; */
    list-style-type: none;
    height: 100%;
    width:100%;
    background-color: white;
    overflow: auto;
}

.side_menu>li{
    border-bottom: 1px solid rgb(202, 202, 202, 0.43);
}

.side_menu>li>a, .side_menu>li>button, .side_menu>li>span{
    width:100%;
    padding: 10px 10px;
    display: inline-block;
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.sub_menu{
    background-color: white;
    list-style-type: none;
    display: none;
    padding-bottom: 10px;
}

.sub_menu>li{
    padding-left: 20px;
}

.sub_menu a{
    color: black;
    padding: 5px 0px;
    text-decoration: none;
    display: block;
}

.menu_icon{
    width: 20px;
    height: 20px;
}

.toogle-btn{
    position: absolute;
    top: 0px;
    right: -40px;
    z-index: 2;
    width:40px;
    height: 40px;
    overflow: hidden;
    background: white;
    cursor: pointer;
    padding: 5px;
}

.toogle-btn p {
    height: 3px;
    width: 100%;
    background: black;
    margin: 5px 0px;
    transition:transform 1s;
} 

.toogle-btn-p1 {
    transform: rotate(45deg) translate(4px, 6px);
}

.toogle-btn-p2 {
    display: none;
}

.toogle-btn-p3 {
    transform: rotate(-45deg);
}

.form-wrapper{
    background: white;
    padding: 20px;
    border-radius: 2px;
    margin: 20px 0px;
    overflow: hidden;
}

label{
    margin-bottom: 5px; 
}

.form-wrapper>h2{
    border-bottom: 3px solid #2e56b3;
    padding-bottom: 10px;
    font-size: 25px;
    position: relative;
}

.head_h2{
    border-bottom: 3px solid #2e56b3;
    padding: 3px 5px 5px 46px;
    font-size: 25px;
}

.form-modle{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.945);
    overflow: auto;
    z-index: 1;
}

.form-modle form{
    position: relative;
    margin: 50px auto 0px;
    max-width: 400px;
    background: white;
    box-shadow: 0 0 5px gray;
    padding: 0px 0px 10px;
    border-radius: 2px;
    
}

.form-control{
    padding: 1px 10px !important;
    margin:0px !important

}

.mb1{
    margin-bottom: 10px !important;
}

label{
    margin: 0px !important;
}

.close-btn{
    position: absolute;
    top: 9px;
    left: 11px;
    font-size: 21px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.close-btn2{
    position: absolute;
    top: 6px;
    left: 5px;
    font-size: 21px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

table tr{
    padding: 5px 0px !important;
}

table td{
    padding: 0px 5px !important;
}

.chat_con {
    position: fixed;
    bottom: 5px;
    right: 5px;
}

.chat_icon{
    width: 60px;
    height: 60px;
    float: right;
    cursor: pointer;
}

.chat_con_child{
    background: rgba(255, 255, 255, 0.651);
    border-radius: 5px;
    width: 250px;
    max-width: 250px;
    border-radius: 5px;
    overflow: hidden;
}

.chat_header{
    padding: 10px;
    background-color: #6b91eb
}

.chat_online_users{
    max-height: 600px;
    overflow: auto;
}

.live_users{
    padding: 5px 15px 5px 35px;
    background: white;
    border-radius: 15px;
    margin: 5px 5px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.chat_user_icon{
    font-size: 20px;
    background-color: #caddca;
    font-weight: bold;
    border-radius: 15px 0px 0px 15px;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 30px;
    padding-left: 10px;
    display: inline-block;
}

.online {
    background-color: green;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 5px;
}

.message_left{
    background-color: white;
    padding: 10px;
    margin-left: 50px;
    border-radius: 10px
}

.message_right{
    background-color:rgba(128, 128, 128, 0.733);
    padding: 10px;
    margin-right: 50px;
    border-radius: 10px
}

.chat_input_box{
    width: 100%;
    position: relative;
    box-sizing: border-box;
}

.chat_input_box input{
    width: 100%;
    border: 1px solid rgb(141, 184, 240);
    border-radius: 10px;
    padding: 5px 40px 5px 5px;
    box-sizing: border-box;

}

.chat_input_box p{
    position: absolute;
    top: 5px;
    right: 10px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 25px;
    color: #6b91eb;
}

.loader{
    width: 100%;
    text-align: center;
}

.notifi_card{
    margin-top: 8px;
    background-color: #cad9dd;
    padding: 4px 8px 0px 8px;
    box-shadow: 0px 0px 5px rgb(139, 139, 139);
    overflow: hidden;
}

.notifi_card .title {
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 500;
}

.notifi_card .message{
    margin-bottom: 2px;
    word-wrap: break-word;
}

.notifi_card .date{
    text-align: right;
    margin: 0px;
    color: gray;
    font-size: 14px;
}

.thum_card_con {
    width: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08);
    border-radius: 2px;
    border: 1px solid rgba(0,0,0,.1);
    cursor: pointer;
    margin-bottom: 10px;
}

.thum_card_con .card_img {
    width: 100px;
    height: 100%;
    float: left;
}

.thum_card_con .card_body {
    width: calc(100% - 100px);
    float: left;
}

.thum_card_con .card_title { 
    font-size: 18px;
}

.thum_card_con .card_p {
    margin: 0px;
    font-size: 14px;
}

.thum_card_con.active{
    box-shadow: 0 0px 4px 0 #6b91eb;
}

.icon_style{
    font-size: 20px !important;
    cursor: pointer;
    color: #6b91eb;
}

.icon_style:hover {
    color: #3458ac;
}

.typer_con {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-color: gainsboro;
    padding: 10px 2px 3px;
}

.typer_con .textarea_con {
    width: calc(100% - 50px);
    float: left;
    position: relative;
}

.typer_con textarea {
    width: 100%;
    display: inline-block;
    padding: 5px;
    border-radius: 5px;
}

.typer_con button {
    float: right !important;
    padding: 6px 10px;
}

.card_list_area{
    max-height: calc(100% - 110px);
    padding: 10px 0px 100px;
}

.table_sticky_head{
    position: relative;
}

.table_sticky_head th{
    position: sticky !important;
    top: 0;
}

@media only screen and (max-width: 500px) {

    .col-xs-6{
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 3px;
        padding-left: 3px;
    }

    .Logo {
        font-size: 45px;
    }

    .mp0{
        padding-left: 0px;
        padding-right: 0px;
    }

    .form-wrapper{
        padding: 10px 2px;
        border-radius: 2px;
    }

    .form-wrapper>h2{
        font-size: 20px;
        padding-left: 10px;
    }

    .head_h2{
        font-size: 20px;
        padding: 7px 0px 7px 36px;
    }

    .close-btn{
        top: 10px;
        left: 9px;
        font-size: 20px;
    }

    .close-btn2{
        top: 4px;
        left: 8px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 350px){
    .card_icon{
        height: 55px;
    }

    .card_wrapper h5{
        font-size: 15px;
    }
}